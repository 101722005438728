// i18next-extract-mark-ns-start subscriptions
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {SEO} from 'components/SEO';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import recurring_payments from 'images/recurring_payments.png';
import cards from 'images/cards.svg';
import siteTemplate from 'images/site_template.svg';
import click1 from 'images/click1.svg';
import bill from 'images/bill.svg';
import password from 'images/password.svg';
import modular from 'images/modular.svg';

import pay_by_link_EN from 'images/pay-by-link-feature.svg';
import pay_by_link_ES from 'images/pay-by-link-feature_es.svg';
import {IndexBackground as _IndexBackground} from 'components/landings/IndexBackground';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {IndexContent} from 'components/landings/IndexContent';
import {LargeSectionHeader} from 'components/landings/LargeSectionHeader';
import {SignUpButton} from 'components/SignUpButton';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {IndexImage} from 'components/landings/IndexImage';
import {Background as _Background} from 'components/Background';
import {Content} from 'components/Content';
import {Card} from 'components/Card';
import CardsContainer from 'components/CardsContainer';
import {InternalPageLink} from 'components/links/Pages';
import {StyledDiv, StyledImg} from 'components/StyledSx';
import {CompatibleWith, Logo} from 'components/monei-pay/CompatibleWith';
import visa from 'images/visa-logo-small.svg';
import masterCard from 'images/mastercard-logo-small.svg';
import discover from 'images/discover-logo-small.svg';
import jcb from 'images/jcb-logo-small.svg';
import dinersClub from 'images/dinersclub-logo-small.svg';
import applePay from 'images/applepay-logo-small.svg';
import googlePay from 'images/google_pay_color.svg';
import clickToPay from 'images/click_to_pay.svg';
import Typed from 'typed.js';
import {SupportLink} from 'components/links/Support';
import {FaqsSection} from 'components/FaqsSection';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {ScreenSizes} from '../../../types/responsive';
import {AnchorLink} from '../../components/AnchorLink';
import check from '../../images/check_green.svg';
import {BlogLink} from '../../components/links/Blog';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import { RelatedBlogPosts } from 'components/RelatedBlogPosts';
import { mapCategory, getPostsFromPrismicEdges } from 'utils/blog-utils';
import { BlogCategoryType, BlogPost } from '../../../types/BlogTypes';

const Background = styled(_Background)`
  &:after {
    content: none;
  }
  &:before {
    @media (max-width: 768px) {
      top: -100px;
    }
  }
`;

const IndexBackground = styled(_IndexBackground)`
  &:before {
    transform: rotate(-64deg) matrix(1, 0, 0, 1, 650, 0);
    height: 2700px;
    width: 2080px;
    left: 75%;
  }
`;

const Section3Content = styled(Content)`
  margin-top: 150px;
  display: flex;
  align-items: center;
  @media (max-width: ${ScreenSizes.sm}) {
    margin-top: 0;
  }
  ul {
    margin-left: 0;

    li {
      list-style: none;
    }
  }
`;

const FixedSection = styled(Section)`
  height: 250px;
`;

const CheckGreen = styled.img`
  margin: 4px 8px -2px 0;
  svg {
    fill: white;
  }
`;

const logos: Logo[] = [
  {src: visa, height: '21px'},
  {src: masterCard, height: '28px'},
  {src: discover, height: '20px'},
  {src: jcb, height: '24px'},
  {src: dinersClub, height: '24px'},
  {src: applePay, height: '23px'},
  {src: googlePay, height: '23px'},
  {src: clickToPay, height: '21px'}
];

const CardImage = styled.img`
  position: absolute;
`;

const CardWrapper = styled.div`
  max-width: 230px;
  h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.8rem;
  }
`;

const DynamicSpan = styled.span`
  display: inline;
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Subscriptions: React.FC<PageProps> = (props: any) => {
  const {t, language} = useI18next();
  const typeTarget = useRef<HTMLElement>(null);
  const payByLink = language === 'es' ? pay_by_link_ES : pay_by_link_EN;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });

  const businessList = [
    t('SaaS companies'),
    t('streaming services'),
    t('utility providers'),
    t('magazines'),
    t('health') + ' &amp; ' + t('wellness'),
    t('freelancers')
  ];

  useEffect(() => {
    const elementId: any = typeTarget.current !== null ? typeTarget.current : '';
    const typed = new Typed(elementId, {
      strings: businessList,
      typeSpeed: 70,
      loop: true,
      backDelay: 1300
    });

    return () => {
      typed.destroy();
    };
  }, [typeTarget]);

  const faqs = [
    {
      header: t('What is a subscription payment service?'),
      text: t(
        'A subscription payment service is a system that lets you automatically charge customers on a recurring basis.'
      ),
      content: (
        <Trans>
          A subscription payment service is a system that lets you automatically charge customers on
          a recurring basis.
        </Trans>
      )
    },
    {
      header: t('How can a subscription payment service benefit my business?'),
      text: t(
        'Subscription payment services can provide a predictable and stable revenue stream, improve customer retention, automate billing processes, and potentially increase customer lifetime value by offering a convenient payment method.'
      ),
      content: (
        <Trans>
          Subscription payment services can provide a predictable and stable revenue stream, improve
          customer retention, automate billing processes, and potentially increase customer lifetime
          value by offering a convenient payment method.
        </Trans>
      )
    },
    {
      header: t('In what countries is MONEI’s subscription payment service available?'),
      text: t(
        'MONEI Subscriptions are available to merchants based in Spain and Andorra, for transactions made anywhere in the world.'
      ),
      content: (
        <Trans>
          MONEI Subscriptions are available to merchants based in Spain and Andorra, for
          transactions made anywhere in the world.
        </Trans>
      )
    },
    {
      header: t('How do I set up MONEI’s subscription payment service for my business?'),
      text: t(
        "Use the Subscriptions API to integrate MONEI Subscriptions with your custom-built website. You'll need to decide on your pricing model, set up your products or services, and configure the billing cycle."
      ),
      content: (
        <Trans>
          Use the{' '}
          <AnchorLink href="https://docs.monei.com/api/#tag/Subscriptions">
            Subscriptions API
          </AnchorLink>{' '}
          to integrate MONEI Subscriptions with your custom-built website. You'll need to decide on
          your pricing model, set up your products or services, and configure the billing cycle.
        </Trans>
      )
    },
    {
      header: t('How much does a subscription payment service cost?'),
      text: t(
        'The cost of a subscription payment service varies widely depending on the provider. Some charge a flat monthly fee, some take a percentage of each transaction, and some do a combination of both. At MONEI, you only pay transaction fees. Learn more about pricing.'
      ),
      content: (
        <Trans>
          The cost of a subscription payment service varies widely depending on the provider. Some
          charge a flat monthly fee, some take a percentage of each transaction, and some do a
          combination of both. At MONEI, you only pay transaction fees. Learn more about{' '}
          <InternalPageLink slug="pricing">pricing</InternalPageLink>.
        </Trans>
      )
    },
    {
      header: t('Is there a maximum transaction amount for MONEI Subscriptions?'),
      text: t(
        'The maximum amount for subscription payments is €2,500 per transaction. Contact support to request an increase.'
      ),
      content: (
        <Trans>
          The maximum amount for subscription payments is €2,500 per transaction.{' '}
          <SupportLink slug="/requests/new">Contact support</SupportLink> to request an increase.
        </Trans>
      )
    },
    {
      header: t('Is there a minimum transaction amount for MONEI Subscriptions?'),
      text: t(
        'Depending on the payment processor, there is either no minimum or a minimum amount of €0.01 required to authorize subscription payments. If a charge is required to activate subscription payments, it’s automatically refunded unless the first charge is for the real payment amount. Subscription payments are considered active after the first real transaction.'
      ),
      content: (
        <Trans>
          Depending on the payment processor, there is either no minimum or a minimum amount of
          €0.01 required to authorize subscription payments. If a charge is required to activate
          subscription payments, it’s automatically refunded unless the first charge is for the real
          payment amount. Subscription payments are considered active after the first real
          transaction.
        </Trans>
      )
    },
    {
      header: t('How do customers manage their subscriptions?'),
      text: t(
        'Most subscription payment services provide a customer portal where subscribers can manage their accounts. Depending on your integration, with MONEI, your customers can update payment information, change their subscription level, cancel their subscriptions, and more.'
      ),
      content: (
        <Trans>
          Most subscription payment services provide a customer portal where subscribers can manage
          their accounts. Depending on your integration, with MONEI, your customers can update
          payment information, change their subscription level, cancel their subscriptions, and
          more.
        </Trans>
      )
    },
    {
      header: t('How can I prevent churn with a subscription payment service?'),
      text: t(
        'To prevent churn, you can use tactics such as offering a seamless user experience, providing excellent customer service, sending reminders before billing, and offering flexibility in subscription levels.'
      ),
      content: (
        <Trans>
          To prevent churn, you can use tactics such as offering a seamless user experience,
          providing excellent customer service, sending reminders before billing, and offering
          flexibility in subscription levels.
        </Trans>
      )
    },
    {
      header: t('Can I offer trials or discounts with MONEI’s subscription payment service?'),
      text: t(
        'Yes, you can create trial periods. This can be a good way to attract new customers and encourage them to try your service.'
      ),
      content: (
        <Trans>
          Yes, you can create trial periods. This can be a good way to attract new customers and
          encourage them to try your service.
        </Trans>
      )
    },
    {
      header: t('How does MONEI’s subscription payment service handle failed payments?'),
      text: t(
        'If you experience a failed subscription payment due to an expired card or other reasons, we automatically send an email (on your behalf) to the customer asking them to update their payment details. The amount of times we send this notification depends on the subscription plan. If the payment is not recovered, we cancel it and notify you immediately.'
      ),
      content: (
        <Trans>
          If you experience a failed subscription payment due to an expired card or other reasons,
          we <SupportLink slug="/articles/17046659268369">automatically send</SupportLink> an email
          (on your behalf) to the customer asking them to update their payment details. The amount
          of times we send this notification depends on the subscription plan. If the payment is not
          recovered, we cancel it and notify you immediately.
        </Trans>
      )
    },
    {
      header: t(
        'Can I offer multiple subscription plans with MONEI’s subscription payment service?'
      ),
      text: t(
        'Yes, you can offer multiple plans with different pricing tiers and benefits. This can help cater to a wider audience and provide options that suit different customer needs.'
      ),
      content: (
        <Trans>
          Yes, you can offer multiple plans with different pricing tiers and benefits. This can help
          cater to a wider audience and provide options that suit different customer needs.
        </Trans>
      )
    },
    {
      header: t('How secure is MONEI’s subscription payment service?'),
      text: t(
        'MONEI is PCI compliant and prioritizes security, ensuring that sensitive payment information is safely handled. Subscriptions also use encryption and tokenization technology for added security.'
      ),
      content: (
        <Trans>
          MONEI is <InternalPageLink slug="pci-dss">PCI compliant</InternalPageLink> and prioritizes
          security, ensuring that sensitive payment information is safely handled. Subscriptions
          also use encryption and{' '}
          <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
            tokenization technology
          </BlogLink>{' '}
          for added security.
        </Trans>
      )
    },
    {
      header: t('What happens if a customer wants a refund for a subscription payment?'),
      text: t('You can process refunds from your user dashboard.'),
      content: <Trans>You can process refunds from your user dashboard.</Trans>
    },
    {
      header: t("Can I change my subscription pricing once it's set?"),
      text: t(
        'Yes, with MONEI, you can change your subscription pricing. But changes should be communicated clearly to existing subscribers to avoid confusion or dissatisfaction. '
      ),
      content: (
        <Trans>
          Yes, with MONEI, you can change your subscription pricing. But changes should be
          communicated clearly to existing subscribers to avoid confusion or dissatisfaction.
        </Trans>
      )
    },
    {
      header: t(
        'Can I integrate MONEI’s subscription payment services with my custom-built website?'
      ),
      text: t('Yes. Use the Subscriptions API to integrate with your custom-built website.'),
      content: (
        <Trans>
          Yes. Use the{' '}
          <AnchorLink href="https://docs.monei.com/api/#tag/Subscriptions">
            Subscriptions API
          </AnchorLink>{' '}
          to integrate with your custom-built website.
        </Trans>
      )
    },
    {
      header: t('How can I handle currency conversions for subscription payments with MONEI?'),
      text: t(
        'This depends on how the payment terminal is configured. For example, if your business is based in Spain, but your customer has a US card and you want them to pay conversion fees, the terminal is configured in EUR. But if you want to compensate for the fees you can configure the terminal in USD.'
      ),
      content: (
        <Trans>
          This depends on how the payment terminal is configured. For example, if your business is
          based in Spain, but your customer has a US card and you want them to pay conversion fees,
          the terminal is configured in EUR. But if you want to compensate for the fees you can
          configure the terminal in USD.
        </Trans>
      )
    },
    {
      header: t(
        'Can I use a subscription payment service for physical goods, or is it just for digital services?'
      ),
      text: t(
        'Subscription payment services can be used for both physical and digital goods. From software subscriptions to magazine subscriptions to monthly snack boxes, many businesses use these services to manage their recurring revenue.'
      ),
      content: (
        <Trans>
          Subscription payment services can be used for both physical and digital goods. From
          software subscriptions to magazine subscriptions to monthly snack boxes, many businesses
          use these services to manage their recurring revenue.
        </Trans>
      )
    },
    {
      header: t('Can I move my existing subscribers to MONEI’s subscription payment service?'),
      text: t(
        'Yes, but the process can vary between providers. It usually involves exporting customer data from your old system and importing it into the new one. Some services might provide tools or assistance to make this process easier. Contact us for assistance.'
      ),
      content: (
        <Trans>
          Yes, but the process can vary between providers. It usually involves exporting customer
          data from your old system and importing it into the new one. Some services might provide
          tools or assistance to make this process easier.{' '}
          <SupportLink slug="/requests/new">Contact us</SupportLink> for assistance.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <SEO
        title={t('Subscription Payment Service')}
        description={t(
          'Securely accept automatic recurring payments for software subscriptions, memberships, freelance services, and more with MONEI’s subscription payment service.'
        )}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile style={{paddingTop: '60px', paddingBottom: '30px'}}>
            <IndexContent maxWidth="630px">
              <LargeSectionHeader underline tagName="h1">
                <Trans>Put your billing on autopilot with subscription payment services</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Improve the customer experience and your cash flow with subscription payments. Save
                time on billing, build trust, and earn recurring revenue with MONEI Subscriptions.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              width="366px"
              top="27%"
              left="60%"
              src={recurring_payments}
              alt="MONEI recurring payments"
              title="MONEI recurring payments"
              hideLtMd
            />
          </Section>
          <StyledDiv sx={{marginBottom: '80px'}}>
            <CompatibleWith logos={logos} />
          </StyledDiv>
        </Content>
      </IndexBackground>
      <Background>
        <Content style={{marginTop: '50px'}}>
          <FixedSection column style={{textAlign: 'center', marginBottom: '60px'}}>
            <SectionHeader>
              <Trans>Subscription payment services for </Trans>
              <DynamicSpan ref={typeTarget} />
            </SectionHeader>
            <Trans parent="p">
              Free up time to grow your business by automating{' '}
              <InternalPageLink slug="features/recurring-payments">
                recurring payments
              </InternalPageLink>
              .
            </Trans>
          </FixedSection>
          <CardsContainer style={{paddingBottom: '20px'}}>
            <Card>
              <CardImage
                style={{transform: 'translateY(-30%)', top: 0, margin: 0}}
                src={cards}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Accept{' '}
                  <InternalPageLink slug="payment-methods/credit-cards">
                    credit cards
                  </InternalPageLink>
                  , <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-40%)', top: 0, margin: 0, width: 110}}
                src={modular}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Increase conversions with{' '}
                  <SupportLink slug="/articles/17046659268369">automatic retries</SupportLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-40%)', top: 0, margin: 0, width: 110}}
                src={siteTemplate}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Integrate with your custom-built website using a{' '}
                  <AnchorLink href="https://docs.monei.com/api/#tag/Subscriptions">
                    Subscriptions API
                  </AnchorLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-40%)', top: 0, margin: 0, width: 110}}
                src={click1}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Send payment requests to customers via{' '}
                  <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink> to
                  active a subscription
                </Trans>
              </CardWrapper>
            </Card>

            <Card>
              <CardImage
                style={{transform: 'translateY(-30%)', top: 0, margin: 0}}
                src={bill}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Set parameters like subscription names, prices, and billing cycles
                </Trans>
              </CardWrapper>
            </Card>

            <Card>
              <CardImage
                style={{transform: 'translateY(-40%)', top: 0, margin: 0, width: 110}}
                src={password}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="h5">
                  Use{' '}
                  <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/">
                    tokenization
                  </AnchorLink>{' '}
                  to securely store payment information for future transactions
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions sx={{width: '100%', textAlign: 'center'}}>
            <SignUpButton variant="dark">
              <Trans>Get MONEI Now</Trans>
            </SignUpButton>
          </SectionActions>
        </Content>
      </Background>
      <Section3Content>
        <StyledImg
          src={payByLink}
          alt="Recurring payments phone"
          title="Recurring payments phone"
          sx={{
            width: {all: '550px', md: '450px'},
            marginBottom: '0',
            display: {all: 'block', sm: 'none'}
          }}
        />
        <StyledDiv sx={{marginTop: {all: '-80px', md: '0'}}}>
          <SectionHeader>
            <Trans>
              Make it easy for customers to subscribe (and stay subscribed) to your services
            </Trans>
          </SectionHeader>
          <List>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Let customers activate subscriptions with the click of a button.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Build trust with a <SupportLink slug="/articles/360017814717">
                customized
              </SupportLink>{' '}
              payment page: use your logo, brand colors, and URL.
            </Trans>
            <Trans parent="li">
              {' '}
              <CheckGreen src={check} width={16} height={16} />
              Get built-in payment security:{' '}
              <BlogLink slug="understanding-pci-compliance">PCI</BlogLink> and{' '}
              <BlogLink slug="psd2">PSD2</BlogLink> complaint.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Never lose a sale due to outdated payment information with{' '}
              <SupportLink slug="/articles/17046659268369">automatic retries</SupportLink>.
            </Trans>
            <Trans parent="li">
              {' '}
              <CheckGreen src={check} width={16} height={16} />
              Accept consumer-preferred{' '}
              <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>.
            </Trans>
          </List>
          <SectionActions
            sx={{display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap'}}>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ContactSalesButton />
          </SectionActions>
        </StyledDiv>
      </Section3Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '100px'}}
          contentStyle={{maxWidth: {all: '650px', md: '100%'}}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Set up subscription payments today</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Streamline operations and speed up the billing process by saving customer payment
              information for future transactions. Put your recurring payments on autopilot with
              MONEI Subscriptions.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '00',
              right: '70px',
              width: '25%'
            }}
            src={modular}
          />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Subscription payment service FAQ</Trans>} />
        </Section>
      </Content>
      {popularPosts.length > 0 && (
        <>
          <Content>
            <Section sx={{paddingBottom: '0px'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <SectionHeader underline sx={{textAlign: 'center'}}>
                  <Trans>Articles on QR code payments</Trans>
                </SectionHeader>
              </div>
            </Section>
          </Content>
          <RelatedBlogPosts posts={popularPosts} />
        </>
      )}
    </>
  );
};

export default Subscriptions;

export const query = graphql`
  query ($language: String!, $prismicLang: String) {
    locales: allLocale(filter: {ns: {in: ["common", "subscriptions"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
      prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Pagos de suscripción"
        lang: $prismicLang
        first: 4
      ) {
        edges {
          node {
            title
            image
            summary
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
